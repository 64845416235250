import { Component, OnInit } from '@angular/core';
import { AuthService, ILogin } from '../auth.service';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  loading: boolean = false;
  formData: any = {};
  login: ILogin | undefined;
  rememberMe: boolean = false;
  email: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookie: CookieService
  ) { }

  ngOnInit(): void {
    console.log(`referrer: `+window.document.referrer);
    if (localStorage.getItem('rememberMe') === 'true') {
      this.rememberMe = true;
    }
    if (localStorage.getItem('email') && this.rememberMe) {
      this.email = localStorage.getItem('email') || '';
    }
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    this.loading = true;

    this.authService.logIn(email, password).subscribe(result => {
      this.login = result;
      if(this.login !== undefined && this.login.success === true) {
        console.log('success: '+this.login.success);
        localStorage.setItem('rememberMe', this.formData.rememberMe);
        localStorage.setItem('email', this.formData.email);
        this.router.navigate(['/']);
        console.log(`referrer: `+window.document.referrer);
        window.location.href=window.document.referrer;
      }
    }, error => {
      this.loading = false;
      var errorMessage = error.error.message || 'Unable to login. No response from API';
      notify(errorMessage, 'error', 2000);
    });
  }

  clearCookies() {
    this.cookie.deleteCookie('otd-token-temp');
    this.cookie.deleteCookie('otd-userName');
    this.cookie.deleteCookie('otd-user');
    return;
  }

}
